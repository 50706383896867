
import { Component, Vue } from "vue-property-decorator";
import LessonCard from "@/components/LessonCard.vue";
import { getAllLessons, getCourse } from "@/api/api";
import ICourse from "@/types/course";
import ILesson from "@/types/lesson";
import TheLoader from "@/components/TheLoader.vue";
import BackButton from "@/components/BackButton.vue";

@Component({
  components: { BackButton, TheLoader, LessonCard },
})
export default class Lessons extends Vue {
  private courseId: string | undefined;
  private link = "";
  private loading = true;

  private course: ICourse = {
    coverImage: "",
    description: "",
    name: "",
    public: false,
    strictOrder: false,
  };

  private get canManageCourses() {
    return this.$store.getters.canManageCourses;
  }

  private get notFoundText() {
    return `Уроков пока нет. ${
      this.canManageCourses ? "Создать?" : "Обратитесь к администратору."
    }`;
  }
  private get createLink() {
    return `/courses/${this.courseId}/lessons-create`;
  }

  private lessons: ILesson[] = [];

  private async getCourse(courseId: string): Promise<void> {
    try {
      this.course = await getCourse(courseId);
    } catch (e) {
      console.error(e);
    }
  }

  private async getLessons(courseId: string): Promise<void> {
    try {
      this.lessons = await getAllLessons(courseId);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  mounted(): void {
    this.courseId = this.$route.params.courseId;
    this.link = `/courses/${this.courseId}/lessons-create`;
    this.getLessons(this.courseId);
    this.getCourse(this.courseId);
  }
}
